import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';

import {registerRootComponent} from 'expo';
import {QueryClientProvider} from 'react-query';

import {ShellContainerProvider} from '@medad-sep/core';
import {AppVersion} from '@shared/components/AppVersion';
import {defaultTheme} from '@theme/default';

import App from './App';
import {queryClient} from './queryClient';

// todo: remove dummy comment to make autoversion run

const Playground = React.lazy(() => import('./modules/Playground/index'));

import './localization/index';
import './theme/index.less';

const useGlobalPlayground = false;
if (useGlobalPlayground) {
  registerRootComponent(() => (
    <Suspense fallback={<div>loading</div>}>
      <Playground />
    </Suspense>
  ));
} else {
  registerRootComponent(() => (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ShellContainerProvider brandColor={defaultTheme.colorPrimary}>
            <App />
          </ShellContainerProvider>
        </BrowserRouter>
      </QueryClientProvider>

      <AppVersion />
    </Suspense>
  ));
}
