import {Local} from './Local';

export const en: Local = {
  translation: {
    language: {
      en: 'English',
      ar: 'العربية',
    },
    validation: {
      requiredField: 'Required field',
      mustBePositive: 'Must be Positive',
    },
    environments: {
      PRE_PRODUCTION: 'Pre Production',
      PRODUCTION: 'Production',
    },
    general: {
      enable: 'Enable',
      enableAll: 'Enable All',
      homePage: 'Set as Homepage',
      home: 'Home',
    },
    notifications: 'Notifications',
    tags: 'Tags',
    clearFilters: 'Clear Filters',
    filter: 'Filter',
    sort: 'Sort',
    login: 'Login',
    logout: 'Logout',
    generalErrorMsg: 'Sorry, something went wrong.',
    successMsg: 'Done, Successfully',
    actions: 'Actions',
    action: {
      new: 'New',
      edit: 'Edit',
      delete: 'Delete',
      add: 'Add',
      save: 'Save',
      undo: 'Undo',
      redo: 'Redo',
      apply: 'Publish',
      cancel: 'Cancel',
      preview: 'Preview',
      set: 'set',
      accept: 'Accept',
      close: 'Close',
    },
    dashboard: 'Dashboard',
    designer: 'Designer',
    'api-resource': 'Api Resource',
    'global-variables': 'Global Variables',
    configurations: 'Configurations',
    services: 'E-Services',
    configurationsOf: 'Configurations Of',
    properties: 'Properties',
    layers: 'Layers',
    builder: {
      states: 'States',
      createScope: 'Create Scope',
      arrayPlaceholder: 'Enter array (e.g., [1, 2, 3])',
      jsonPlaceholder: 'Enter JSON object (e.g., {"key": "value"})',
      booleanPlaceholder: 'Enter Boolean (e.g., true or false )',
      deleteScopeMsg:
        'Deleting this Scope will remove its States everywhere it is used. Are you sure you want to proceed?',
      deleteStateMsg:
        'Deleting this State will remove its value everywhere it is used. Are you sure you want to proceed?',
      deleteCollectionMsg:
        'Deleting this API collection will remove all its Request everywhere it is used. Are you sure you want to proceed?',
      deleteRequestMsg:
        'Deleting this Request will remove its value everywhere it is used. Are you sure you want to proceed?',
    },
    settings: {
      settings: 'Settings',
      pageSettings: 'Page Settings',
      pageTitle: 'Page title',
      pageIcon: 'Page icon',
      addWidget: 'Add Widget',
      clickToEdit: 'Click on a component to start editing.',
      insertRow: 'Insert new row',
      spacing: 'Spacing',
      measures: 'Measures',
      alignment: 'Alignment',
      noSectionsMsgTitle: 'Create a section first',
      noSectionsMsg:
        'To start editing you need first to create a section, Hover over the editor you well see a (+) button , hit the button and you are good to go or from the below button',
      layout: {
        label: 'Layout',
        width: 'Width',
        minWidth: 'Min Width',
        maxWidth: 'Max Width',
        height: 'Height',
        minHeight: 'Min Height',
        maxHeight: 'Max Height',
        margin: 'Margin',
        padding: 'Padding',
        resizeMode: 'Resize Mode',
        contain: 'Contain',
        cover: 'Cover',
        stretch: 'Stretch',
        repeat: 'Repeat',
        center: 'Center',
        left: 'Left',
        right: 'Right',
        top: 'Top',
        bottom: 'Bottom',
        gap: 'gap',
      },
      style: {
        label: 'Style',
        opacity: 'Opacity',
        color: 'Color',
        background: 'Background',
        backgroundColor: 'Background color',
      },
      typography: {
        label: 'Typography',
        initialText: 'Your content goes here. Edit styles and content or remove this text From the side bar',
        fontSize: 'Size',
        fontWeight: 'Weight',
        textAlign: 'Align',
        start: 'Start',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        thin: 'Thin',
        normal: 'Normal',
        bold: 'Bold',
        lineHeight: 'line Height',
      },
      basic: {
        label: 'Basic',
        text: 'Text',
        url: 'URL',
      },
      button: {
        empty: 'empty',
        filled: 'filled',
        outlined: 'outlined',
        danger: 'danger',
        info: 'info',
        neutral: 'neutral',
        primary: 'primary',
        success: 'success',
        warning: 'warning',
      },
      accordion: {
        newSection: 'New Section',
      },
      borders: {
        label: 'Borders',
        borderWidth: 'Border width',
        borderRadius: 'Border Radius',
        borderColor: 'Border Color',
        width: 'width',
        color: 'Color',
        radius: 'Radius',
      },
      flex: {
        label: 'Flex',
        direction: 'Direction',
        wrap: 'Wrap',
        align: 'Align',
        alignSelf: 'Align Self',
        justify: 'Justify',
        row: 'Row',
        column: 'Column',
        nowrap: 'No Wrap',
        wrapReverse: 'Wrap Reverse',
        flexStart: 'Start',
        flexEnd: 'End',
        center: 'Center',
        stretch: 'Stretch',
        spaceEvenly: 'Space Evenly',
        spaceBetween: 'Space Between',
        spaceAround: 'Space Around',
        grow: 'Grow',
      },
    },
    viewportSizes: {
      mobile: 'Mobile',
      tablet: 'Tablet',
      desktop: 'Desktop',
    },
    appConfig: {
      title: 'App',
      logo: 'Logo',
      icon: 'Icon',
      icons: 'Icons',
      splashScreen: 'Splash Screen',
      brandColors: 'Brand Color',
      generalSettings: 'General Settings',
    },
    deploySettings: {
      title: 'Deploy Settings',
      buildApp: 'Build App',
      deployApp: 'Deploy App',
      downloadApp: 'Download App',
      nextPage: 'Next Page',
      previousPage: 'Previous Page',
      android: 'Android',
      ios: 'IOS',
      all: 'All',
      none: 'None',
      showError: 'Show Error',
      buildError: 'Build Error',
      failedToFetchConfig: 'Failed To Fetch Config',
      loadingConfig: 'Loading Config',
      configuration: 'Configuration',
      error: 'Error',
      version: 'Version',
      steps: 'Steps',
      status: 'Status',
      buildHistory: 'Build History',
      testConnection: 'Test Connection',
      EASConfiguration: 'EAS Configuration',
      platform: 'Platform',
      selectPlatform: 'Select Platform',
      newBuildsOccurMessage: 'The maximum number of builds depends on your EAS account plan.',
      somethingWentWrong: 'Something went wrong',
      easConnectedSuccessfully: 'EAS Connected successfully',
      doneSuccessfully: 'Done Successfully',
      usernameOrPasswordIncorrect: 'Username or password is incorrect',
      easToken: 'EAS Token',
      testApp: 'Test App',
      ascAppId: 'ASC App ID',
      androidTrack: 'Android Track',
      selectAndroidTrack: 'Select Android Track',
      profile: 'Profile',
      selectProfile: 'Select Profile',
      preview: 'Preview',
      production: 'Production',
    },
    homeConfig: {
      title: 'Components Customizations',
      enable: 'Enable/Disable',
    },
    appletConfig: {
      enable: 'Enable/Disable',
      componentsCustomizations: 'Components Customizations',
      pleaseEnableConnector: 'To view available services, please enable this connector',
      general: 'General',
    },
    servicesAuthorization: {
      title: 'Services Authorization',
    },
    generalConfig: {
      title: 'General',
    },
    apiSettings: {
      noCollectionSelected: 'No API request has been selected yet for this collection',
      noCollectionSelectedDes: 'Please create an API request first',
      apiUrl: 'URL',
      method: 'Method',
      headers: 'Headers',
      params: 'Params',
      queryParams: 'Query Parameters',
      addHeader: 'Add header',
      key: 'Key',
      value: 'Value',
      body: 'Body',
      testAndPreview: 'Test and Preview',
      responsePath: 'Response Path',
      testAndMapApi: 'Test & Map Response',
      testApiCall: 'Test API call',
      recommended: 'Recommended',
      addJsonPath: 'Add JSON Path',
      predefinedPath: 'Predefined Path',
      jsonPath: 'JSON Path',
      name: 'Name',
      type: 'Type',
      enterCollectionTitle: 'Type collection title...',
      apiDetails: 'Details',
      editAPICall: 'Edit API',
      addCollection: 'Add Collection',
      editCollection: 'Edit Collection',
      defaultValue: 'Default value',
      collections: 'Collections',
      setValue: 'Set value From API',
      editAPIName: 'Edit Collection',
      addQueryParam: 'Add Query parameter',
      apiUpdated: 'API request updated',
      apiDeleted: 'API request Deleted',
      deleteAPIRequest: 'Delete Request',
      deleteAPIRequestMsg: 'Are you sure you want to delete this request?',
      deleteCollection: 'Delete Collection',
      deleteCollectionMsg: 'Are you sure you want to delete this collection?',
    },
    globalVariables: {
      name: 'Global Variables',
      scopes: 'Scopes',
      createScope: 'Create Scope',
      createVariable: 'Create Variable',
      editVariable: 'Edit Variable',
      secret: 'Secret',
      deleteVariableMsg:
        'Deleting this Variable will remove it everywhere it is used. Are you sure you want to proceed?',
      variableName: 'Name',
      variableValue: 'Value',
      variableType: 'Type',
      scopeName: 'Scope Name',
      editScope: 'Edit Scope',
      deleteScopeMsg: 'Deleting this Scope will remove it everywhere it is used. Are you sure you want to proceed?',
      editVariableSuccess: 'Variable is updated successfully',
      createVariableSuccess: 'Variable is created successfully',
      deleteVariableSuccess: 'Variable is deleted successfully',
      editScopeSuccess: 'Scope is updated successfully',
      createScopeSuccess: 'Scope is created successfully',
      deleteScopeSuccess: 'Scope is deleted successfully',
      nameRequired: 'Name is required',
      valueRequired: 'Value is required',
    },
    name: 'Name',
    value: 'Value',
    public: 'Public',
    private: 'Private',
    elements: 'Elements',
    widgets: 'Widgets',
    content: 'Content',
    header: 'header',
    design: 'Design',
    variant: 'Variant',
    type: 'Type',
    pages: 'Pages',
    section: 'Section',
    container: 'Container',
    addElement: 'Add Element',
    changeIcon: 'Change Icon',
    selectRequiredRoles: 'Select Required Roles',
    servicePage: 'Service Page',
    contentPage: 'Content Page',
    selectService: 'Select Service',
    launcher: 'Launcher',
    launcherTop: 'Launcher Top',
    launcherBottom: 'Launcher Bottom',
    auxiliaryPages: 'Auxiliary Pages',
    auxiliaryPage: 'Auxiliary Page',
    pagesGroup: 'Pages Group',
    upload: 'Upload',
    others: 'Others',
    placeholder: 'Placeholder',
    sessions: {
      title: 'Sessions',
      enable: 'Enabled',
      disable: 'Disabled',
      setSession: 'Set Session Time Limit',
    },
    home: 'Home',
    highlighted: 'Highlighted',
  },
};
