import {AliasToken} from 'antd/es/theme/interface/alias';

export interface ThemeExtensionTokens {
  colorSecondary: string;
  colorSecondaryLight: string;
}

export const defaultTheme: Partial<AliasToken & ThemeExtensionTokens> = {
  fontFamily: 'Tajawal',
  colorPrimary: '#006fb8',
  colorSecondary: '#2aa3e4',
  colorSecondaryLight: '#0082c93d',
  colorLink: 'var(--colorPrimary)',
  colorLinkActive: 'var(--colorSecondary)',
};
